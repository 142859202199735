import { Autocomplete, TextField, createFilterOptions } from '@mui/material';
import React from 'react';
import { IDNADDDetails } from '../../../../DNA/contracts/IDNADDDetails';

const PrwAutoComplete = (props: any) => {
    const { options, placeholder, label, ...restProps } = props;    
    options["optionLabel "] = options["optionLabel"] || label;
    options["optionValue "] = options["optionValue"] || "value";  
    options["dropdownOptions"] = options["dropdownOptions"] || [];
    options["placeholder"] = options["placeholder"] || placeholder;
        
    const [selValue, setValue] = React.useState(options[0])    
    const filter = createFilterOptions<IDNADDDetails>();
           
    return(        
        <Autocomplete 
            disabled = {options.disabled}
            id={options.optionLabel}
            defaultValue={{ inputValue: options.defaultValue, id: options.defaultValue, name: options.defaultValue, type: options.defaultValue }}
            //value={value}
            options={options.dropdownOptions}                         
            onChange={(e: any, newValue) => {                    
                if (newValue && newValue.inputValue){                      
                    restProps.onChange(newValue?.inputValue ?? newValue?.name);
                    setValue(newValue?.inputValue ?? newValue?.name);
                }  
                else{                        
                    restProps.onChange(newValue?.name);
                    setValue(newValue?.name);
                }                
            }} 
            
            filterOptions={(options, params) => {
                const filtered = filter(options, params);                
                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some((option) => inputValue === option.name);
                let ddType = options.length > 0 ? options[0].type : "";
                if (inputValue !== '' && !isExisting && (options.length === 0 || options[0].type !== "D")) {
                    filtered.push({
                        inputValue,
                        id: inputValue,
                        name: `Add "${inputValue}"`,
                        type: ddType,
                    });
                }
                
                return filtered;
            }}              
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}

            isOptionEqualToValue={(option, value) => {                 
                return option.name === value.name;
            }}  
            renderOption={(props, option) => <li {...props}>{option.name}</li>}            
            sx={{ width: "100%" }}
            renderInput={(params) => (
                <TextField {...params} placeholder= {options.optionLabel} label = {options.optionLabel} />
            )}
        />        
    );
};
export default PrwAutoComplete;