export const apigatewayurl = "admin/";
export const getCentersForDropDownUrl = apigatewayurl + "Common/GetCenter";
export const getOrganizationsForDropDownUrl = apigatewayurl + "Common/GetOrganization";
export const getUsersForDropDownUrl = apigatewayurl + "User/GetAll";
export const getUserByEmailUrl = apigatewayurl + "User/GetByEmail";
export const getUserByCenterUrl = apigatewayurl + "User/GetByCenter";
export const manageUserThemeUrl = apigatewayurl + "User/ManageUserTheme";
export const getTimeZonesForDropDownUrl = apigatewayurl + "Common/GetTimeZones";

export const APP_ENCRYPT_KEY = "this is crypto key for ewfm";
export const eWFMClientID = "puvpldJGWYuj8LhbpzECnYBklR4HushC";

export const REGEX_EMAIL = new RegExp(/^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/);
export const REGEX_PASSWORD_STRENGTH = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})$/);
export const REGEX_PASSWORD = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
export const REGEX_IMAGE_EXTENSIONS = new RegExp(/\.(jpg|jpeg|png)$/);
export const REGEX_EXCEL_FILE_EXTENSIONS = new RegExp(/\.(xls|xlsx|csv)$/);

export const ACCEPT_EXCEL_OR_CSV_FILE = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
export const SESSION_IDLE_TIME_OUT_TIME = 3600000;
export const RTD_WIDGET_PATH = "/report/rtd/widget";

export const Privileges = {
    "WFO": 1,
    "PlanningAndForecasting": 2,
    "AccessMangement": 3,
    "ExportToExcel": 4,
    "SelectUser": 5,
    "Reports": 6,
    "ShiftReport": 7,
    "UserReport": 8,
    "UserDetailReport": 9,
    "Verint": 10,
    "RoleDetail": 11,
    "UserPrivileges": 12,
    "VerintActivity": 13,
    "AgentSkill": 14,
    "TimeOff": 15,
    "GetTimeOff": 16,
    "UploadTimeOff": 17,
    "AgentDomainMapping": 18,
    "CanEditMapping": 19,
    "Forecasting": 20,
    "CanVerifyMapping": 21,
    "CanAddMapping": 22,
    "CanViewAll": 23,
    "CanViewSubordinates": 24,
    "CanUpload": 25,
    "CanDelete": 26,
    "CanView": 27,
    "Administration": 28,
    "CanDeleteADM": 29,
    "CanViewByManager": 30,
    "VerintUser": 33,
    "BlueprintUser": 34,
    "ViewSchedules": 35,
    "ViewOvertimeSchedules": 37,
    "UpdateSchedules": 38,
    "UpdateOverimeSchedules": 40,
    "DNAAdmin": 44,
    "Dashboard": 45,
    "ViewDashboard": 46,
    "CanAddDashboard": 47,
    "CanDeleteDashboard": 48,
    "CanSaveWidget": 49,
    "CanSaveAsWidget": 50,
    "CanAddWidget": 51,
    "Payroll": 52,
    "CanFirstApproval": 53,
    "CanFinalApproval": 54,
    "CanDecline": 55,
    "CanDeleteDNAQueue": 56,
    "CanOnlyViewDNA": 57,
    "CanShareDashboard": 58,
    "Widgets": 60,
    "AgentStatusView": 61,
    "AgentStatusSummaryView": 62,
    "MarketAnalysisView": 63,
    "EmailQueueAnalysisView": 64,
    "EmailAgeAnalysisView": 65,
    "ChatAnalysisView": 66,
    "ChatAgeAnalysisView": 67,
    "ModifyDefaultFormatting": 68,
    "Staffing": 69,
    "HeadCount": 70,
    "VerintIntegration": 71,
    "VisitorChatStats": 72,
    "DesignRequestStatus" : 73,
    "EmailAnalysisView":74,
    "HeadCountPlanner":75,
    "Employees":76,
    "Mappings": 77,
    "MasterPages":78,
    "Settings":79,
    "InputParameters": 80,
    "HCSolver": 81,
    "HCPReports": 82
}

export const ImageCropper = {
    rotation: {
        forward: 90,
        reverse: -90
    }
}


