import { DNAAdminTabValue } from "../../../enums/common.enum"
export const apiUrl = "admin/"
export const getDNADomainMappingUrl = apiUrl + "dna/getdnadomainmappingdetails";
export const manageDNADomainMappingUrl = apiUrl + "dna/manageDNADomainMapping";
export const deleteDNADomainMappingUrl = apiUrl + "dna/deleteDNADomainMapping";
export const getDnaLobOrganizationsForDropDownUrl = apiUrl +"dna/GetDnaLobOrganization";
export const dNADomainMappingApprovalUrl = apiUrl + "dna/dNADomainMappingApproval";
export const getDNACommentUrl = apiUrl + "dna/getDnaCommentDetails";
export const saveDNACommentUrl = apiUrl + "dna/saveDnaComments";
export const getDNADropdownsUrl = apiUrl + "dna/getDnaDropdowns";

export const newExportToExcelLOBFieldsArr = ["Org ID","Organization Name","LOB","Domain","Channel", "Region", "Org", "Office", "Status"];
export const oldExportToExcelLOBFieldsArr = ["orgID","organizationName","lob","domain","channel", "region", "org", "office", "status"];

export const newExportToExcelQueueFieldsArr = ["QUEUE_NAME","FORECAST_GROUP","REGION","COUNTRY_MARKET","COUNTRY_SEGMENT", "WEB_COUNTRY", "CONTACT_TYPE", "BUSINESS_VERTICAL", "HIERARCHY_L1", "HIERARCHY_L2", "PLANNING_ENTITY", "LANG", "CRM", "DOMAIN", "EFFECTIVE DATE"];
export const oldExportToExcelQueueFieldsArr = ["queueName","forecasT_GROUP","region","countrY_MARKET","countrY_SEGMENT","weB_COUNTRY","contacT_TYPE","businesS_VERTICAL","hierarchY_L1","hierarchY_L2","planninG_ENTITY","lang","crm","domain","effectiveDate"];

export const newExportToExcelActivityFieldsArr = ["Activity Name","Level_1","Level_2","Level_3","Level_4", "Level_5", "Activity Type", "Status", "IsPaid"];
export const oldExportToExcelActivityFieldsArr = ["activityName","level_1","level_2","level_3","level_4","level_5","activityType","status", "isPaid"];

export class DNAAdminGenericConstants {
    static readonly Tabs = [        
        { 
            headerKey: "LOBs",
            addLabelKey: "Add LOB",
            editLabelKey: "Edit LOB",
            value: DNAAdminTabValue.LOB
        },
        { 
            headerKey: "QUEUEs",
            addLabelKey: "Add Queue",
            editLabelKey: "Edit Queue",
            value: DNAAdminTabValue.QUEUE
        },
        { 
            headerKey: "ACTIVITIEs",
            addLabelKey: "Add Activity",
            editLabelKey: "Edit Activity",
            value: DNAAdminTabValue.Activity
        }
    ]
}

export const DNAMappingConstants = {
    Approved: "Approved",
    Declined: "Declined",
    Open: "Open",
    FirstLevelApproved: "First Level Approved",
    LOB: "LOBs",
    QUEUE: "QUEUEs",
    ACTIVITY: "ACTIVITIEs"
}

export const DNATabConstantValues = {    
    LOB: "1",
    QUEUE: "2",
    ACTIVITY: "3"
}
