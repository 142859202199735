import { akitaDevtools, persistState, resetStores } from "@datorama/akita";
import { Toast } from "primereact/toast";
import React, { createContext, useContext, useRef } from "react";
import SimpleCrypto from "simple-crypto-js";
import {
  getCentersForDropDownUrl,
  getOrganizationsForDropDownUrl,
  getUserByEmailUrl,
  getUsersForDropDownUrl,
  APP_ENCRYPT_KEY,
  getUserByCenterUrl,
  manageUserThemeUrl,
  getTimeZonesForDropDownUrl
} from "../constants/CommonConstant";

import { useAxiosContext } from "../contextProviderFiles/AxiosContextProvider";

let operationsAllowed: any = {};
const CommonServiceContext = createContext(operationsAllowed);

export const CommonServiceProvider = (props) => {
  let persistStorage: any;
  let simpleCrypto: SimpleCrypto;

  const myToast = useRef<any>(null);
  const { axiosObj } = useAxiosContext();

  const setupApplication = () => {
    setupCryptography();
    setupStateManagement();
  };

  const tearDownApplication = () => {
    resetStateManagement();
  };

  const setupCryptography = () => {
    simpleCrypto = new SimpleCrypto(APP_ENCRYPT_KEY);
  };

  const setupStateManagement = () => {
    if (process.env.NODE_ENV !== "production") {
      akitaDevtools();
    }

    persistStorage = persistState({
      include: ["session"],
      preStorageUpdate(storeName, state) {
        // This function is called before storing data into local storgage.
        if (simpleCrypto) {
          return simpleCrypto.encrypt(state);
        }
        return state;
      },
      preStoreUpdate(storeName, state) {
        // This function is called before storing data in in-memory storage.
        // E.g, what you see in Redux tab in browser.
        if (simpleCrypto) {
          return simpleCrypto.decrypt(state);
        }
        return state;
      },
    });
  };

  const getInitialsOfFullName = (firstName: string, lastName: string) => {
    if (firstName && lastName) {
      return firstName[0] + lastName[0];
    }
  };

  const resetStateManagement = () => {
    resetStores();

    if (persistStorage) {
      // It is important to execute this transaction as async operation because we need to wait for 'resetStores' to complete the process.
      setTimeout(() => {
        persistStorage.clearStore();
      }, 0);
    }
  };

  const getUsersForDropDown = (request) => {
    return axiosObj.post(getUsersForDropDownUrl, request);
  };

  const getCentersForDropDown = (request) => {
    return axiosObj.get(getCentersForDropDownUrl, request);
  };

  const getOrganizationsForDropDown = (request) => {
    return axiosObj.get(getOrganizationsForDropDownUrl, request);
  };

  const getUserByEmail = (request) => {
    return axiosObj.post(getUserByEmailUrl, request);
  };

  const getUserByCenter = (request) => {
    return axiosObj.post(getUserByCenterUrl, request);
  };

  const showToast = (type, message) => {
    myToast.current?.show({ severity: type, detail: message, life: 6000 });
  };

  const manageUserTheme = (request) => {
    return axiosObj.post(manageUserThemeUrl, request);
  };

  const getTimeZonesForDropDown = (request) => {
    return axiosObj.get(getTimeZonesForDropDownUrl, request);
  };

  operationsAllowed = {
    getUsersForDropDown,
    getCentersForDropDown,
    getOrganizationsForDropDown,
    getUserByEmail,
    showToast,
    setupApplication,
    tearDownApplication,
    getUserByCenter,
    getInitialsOfFullName,
    manageUserTheme,
    getTimeZonesForDropDown
  };

  return (
    <CommonServiceContext.Provider value={operationsAllowed}>
      <Toast ref={myToast} />
      {props.children}
    </CommonServiceContext.Provider>
  );
};

export const useCommonService = () => {
  return useContext(CommonServiceContext);
};
